import { useSelector } from 'react-redux';
import ErrorTemplate from '../../../components/Error/ErrorTemplate';
import Spinner from '../../../components/Spinner/Spinner';
import { useOnFocusable } from '../../../helpers/hooks/useOnFocusable';
import { useQueryTemplate } from '../../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import { FromProp } from '../../../server/modules/fetchWithQuery/types';
import { FetchDetails, FetchRequestTypes } from '../../../services/types';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import { LocationStateContext } from '../../../typings/routing';
import { IStubState } from '../data/formatter';
import Stub from './Stub';

export type StubContainerProps = {
  contentGridStubData?: IStubState;
  onClickParameters?: FetchDetails['onClickParameters'];
  onFocusable?: () => void;
  routingContext?: LocationStateContext;
  url?: string;
} & FromProp;

function StubContainer({
  contentGridStubData,
  from,
  onClickParameters,
  onFocusable,
  routingContext,
  url,
}: StubContainerProps): JSX.Element | null {
  const isTvDevice = useSelector(displayTVModeSelector);

  const [{ isLoading, error, data }] = useQueryTemplate<IStubState>(
    url,
    { from, onClickParameters, routingContext, template: FetchRequestTypes.Stub },
    {
      enabled: !contentGridStubData,
      initialData: contentGridStubData,
    }
  );
  const { text, title, URLImage, button, onClick } = contentGridStubData || data || {};

  useOnFocusable(onFocusable, !!data);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorTemplate error={error} />;
  }

  if (!data) return null;

  return (
    <Stub
      text={text}
      title={title}
      URLImage={URLImage}
      onClick={onClick}
      isTvDevice={isTvDevice}
      buttonLabel={button?.title}
    />
  );
}

export default StubContainer;
